import React, { Component } from 'react';
import Sectiontitle from '../component/Banner/Sectiontitle';
import Fade from 'react-reveal/Fade';
import serviceImg from '../image/app/services.png';
import { Parallax } from 'react-parallax';
import { FormattedMessage } from 'react-intl';

class Service extends Component {
    render() {
        const divStyle = {
            backgroundImage: 'url(' + serviceImg + ')',
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "100%",
            color: "#f5f5f5"
        }
        return (
            <section id="service">
                <div className="card_2" style={divStyle}>
                    <h1 className="card-tittle"><FormattedMessage id="services.title" defaultMessage="" /></h1>
                    <Fade bottom duration={1000}>
                        <div className="container">
                            <div className="work_item wow">
                                
                                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.2" defaultMessage="" /></b></p>
                                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.1" defaultMessage="" /></b></p>
                                    <ul>
                                        <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
                                        <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
                                        <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
                                    </ul>
                       
                                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.3" defaultMessage="" /></b></p>
                                    <ul>
                                        <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
                                        <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
                                        <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
                                        <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
                                    </ul>
                                
                            </div>
                        </div>
                    </Fade>
                    </div>
            </section>
        )
    }
}

export default Service;



//<Parallax bgImage={require('../image/app/services.png')} strength={350} className="testimonial_area">
//    <div className="card_2">
//        <h1 className="card-tittle"><FormattedMessage id="services.title" defaultMessage="" /></h1>
//        <Fade bottom duration={1000}>
//            <div className="container">
//                <div className="work_item wow">

//                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.2" defaultMessage="" /></b></p>
//                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.1" defaultMessage="" /></b></p>
//                    <ul>
//                        <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
//                        <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
//                        <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
//                    </ul>

//                    <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.3" defaultMessage="" /></b></p>
//                    <ul>
//                        <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
//                        <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
//                        <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
//                        <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
//                    </ul>

//                </div>
//            </div>
//        </Fade>
//    </div>
//</Parallax>


//return (
//    <section id="service">
//        <Parallax bgImage={require('../image/app/services.png')} strength={350} className="testimonial_area">
//            <div className="card_2">
//                <h1 className="card-tittle"><FormattedMessage id="services.title" defaultMessage="" /></h1>
//                <Fade bottom duration={1000}>
//                    <div className="container">
//                        <div className="work_item wow">

//                            <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.2" defaultMessage="" /></b></p>
//                            <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.1" defaultMessage="" /></b></p>
//                            <ul>
//                                <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
//                                <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
//                                <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
//                            </ul>

//                            <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.3" defaultMessage="" /></b></p>
//                            <ul>
//                                <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
//                                <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
//                                <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
//                                <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
//                            </ul>

//                        </div>
//                    </div>
//                </Fade>
//            </div>
//        </Parallax>
//    </section>
//)



//return (
//    <section className={`${wClass}`} id="service" style={{
//        backgroundImage: 'url(' + serviceImg + ')',
//        backgroundSize: "cover",
//        height: "90vh",
//        color: "#f5f5f5"
//    }} >
//        <div className="card_2">
//            <h1 className="card-tittle"><FormattedMessage id="services.title" defaultMessage="" /></h1>
//            <Fade bottom duration={1000}>
//                <div className="container">
//                    <div className="work_item wow">

//                        <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.2" defaultMessage="" /></b></p>
//                        <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.1" defaultMessage="" /></b></p>
//                        <ul>
//                            <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
//                            <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
//                            <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
//                        </ul>

//                        <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.3" defaultMessage="" /></b></p>
//                        <ul>
//                            <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
//                            <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
//                            <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
//                            <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
//                        </ul>

//                    </div>
//                </div>
//            </Fade>
//        </div>
//    </section>
//)

















//<Sectiontitle Title={<FormattedMessage id="services.title" defaultMessage="" />} />

//<Fade bottom cascade duration={1000}>
//    <div className="row">
//        <div className="col-lg-6 col-sm-6" >
//            <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
//                <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.2" defaultMessage="" /></b></p>
//                <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.1" defaultMessage="" /></b></p>
//                <ul>
//                    <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
//                    <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
//                    <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
//                </ul>
//            </div>
//        </div>
//        <div className="col-lg-6 col-sm-6" >
//            <div className="work_item wow fadeInUp" data-wow-delay="0.1s">

//                <p style={{ textTransform: 'uppercase' }}><b><FormattedMessage id="services.main.3" defaultMessage="" /></b></p>
//                <ul>
//                    <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
//                    <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
//                    <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
//                    <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
//                </ul>
//            </div>
//        </div>
//    </div>
//</Fade>


//class Service extends Component {
//    render() {
//        let jhonData = this.props.jhonData;
//        var { wClass } = this.props
//        return (
//            <section className={`${wClass}`} id="service">
//                <div className="container">
//                    <Sectiontitle Title="What I'm Doing" TitleP="I love what I do. I take great pride in what I do." />
//                    <Fade bottom cascade duration={1000}>
//                        <div className="row">
//                            {
//                                jhonData.service && jhonData.service.map(item => {
//                                    return (
//                                        <div className="col-lg-4 col-sm-6" key={item.id}>
//                                            <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
//                                                <i className={item.iconName}></i>
//                                                <a href=".#"><h2 className="t_color">{item.serviceTitle}</h2></a>
//                                                <p>{item.sDetails}</p>
//                                            </div>
//                                        </div>
//                                    )
//                                }
//                                )
//                            }
//                        </div>
//                    </Fade>
//                </div>
//            </section>
//        )
//    }
//}

//export default Service;