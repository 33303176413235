// react
import React from 'react';


//MM
import { connect } from 'react-redux';
import { getVisitsList, getHoursList, saveVisit, getUserVisits } from '../../store/visits';
import _ from 'lodash';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import { toast } from "react-toastify";

const MONTHS = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
];

const WEEKDAYS_LONG = [
    'Niedziela',
    'Poniedziałek',
    'Wtorek',
    'Środa',
    'Czwartek',
    'Piątek',
    'Sobota',
];

const WEEKDAYS_SHORT = ['Nie','Pn', 'Wt', 'Śr', 'Czw', 'Pi', 'Sob'];
class AccountPageUserPanel_2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currDate: "" };
    }

    componentDidMount = () => {
        this.props.getVisitsList();
    };

    saveVisit = (id) => {
        if (this.props.isSignedIn) {
            this.props.saveVisit(id);
        }
        else {
            toast.info("Zaloguj się aby zapisać się na wizytę.");
        }
    };

    handleDayClick = (day, { selected }) => {
        if (selected === true) {
            this.setState(state => ({ currDate: day.toLocaleDateString() }));
            this.props.getHoursList(day);
        }
    }

    render() {
        const value = this.props.visitsList;
        const dateList = value.map((d) => new Date(d.replace(/-/g, "/")));
        const initialYear = new Date().getFullYear();
        const initialMonth = new Date().getMonth();

        const btnList = this.props.hoursList;
        const hourNumber = btnList.length;
        
        return (
            <React.Fragment>
                <div className="row" >
                    <div className="col-md-6 d-flex">
                        <div className="flex-grow-1 mb-md-0">
                            <div className="card-body" style={{ lineHeight: '20px' }}>
                                <DayPicker initialMonth={new Date(initialYear, initialMonth)} selectedDays={dateList} onDayClick={this.handleDayClick}
                                    months={MONTHS}
                                    weekdaysLong={WEEKDAYS_LONG}
                                    weekdaysShort={WEEKDAYS_SHORT}
                                    firstDayOfWeek={1}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex">
                        <div className="flex-grow-1 mb-md-0">
                            <div className="card-body">
                                <p>Wybrana data: <b>{this.state.currDate}</b></p>
                            
                                <div>
                                    {(hourNumber === 0 && this.state.currDate.trim() !== "") ? "Brak wolnych wizyt w tym dniu" : btnList.map((btn) => <button key={btn.id} type="button" onClick={(e) => this.saveVisit(btn.id, e)} className="btn btn-outline-success m-1">{btn.timeStarts}</button>)}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        visitsList: _.values(state.visits.visitsList),
        hoursList: _.values(state.visits.hoursList),
        err: state.error
    };
};


export default connect(mapStateToProps, { getVisitsList, getHoursList, saveVisit, getUserVisits })(AccountPageUserPanel_2);
