import visits from './visitsApis';
import { GET_ALL_VISITS, GET_VISITS_HOURS, SING_UP_VISIT, SING_OFF_VISIT, USER_VISITS } from './visitsActionTypes';
import { toast } from "react-toastify";


export const getVisitsList = () => async (dispatch, getState) => {
    try {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };

        let visitList = await visits.get('/GetList', options);
        let { data } = visitList;
        dispatch({ type: GET_ALL_VISITS, payload: data });
    }catch (err) {
     
    }
};

export const getHoursList = (selectedDate) => async (dispatch, getState) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let currentDate = formatDate(selectedDate);
    visits.get('/GetHoursList/' + currentDate, options).then((response) => {
        let { data } = response;
        dispatch({ type: GET_VISITS_HOURS, payload: data });
    }).catch(err => {
    });
};


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export const saveVisit = (visitId) => async (dispatch, getState) => {
    try {
        const state = getState();
        let token = state.auth.userToken;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
                'visitId': visitId
            }
        };
        const params = {
            "visitId": visitId,
        };

        let saveVisit = await visits.post('/SignUpVisit', params, options);
        dispatch({ type: SING_UP_VISIT, payload: visitId });
        toast.success("Udało się zapisać na wizytę.");
        toast.info("Informacja o wizycie została wysłana na Twoją skrzynkę odbiorczą");
 
        const options2 = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let userVisits = await visits.get('/GetUserVisits', options2);
        let { data } = userVisits;
        dispatch({ type: USER_VISITS, payload: data });

    } catch (err) {
        toast.error("Nie udało się zapisać na wizytę");
    }
};


export const getUserVisits = () => async (dispatch, getState) => {
    const state = getState();
    let token = state.auth.userToken;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
        }
    };

    visits.get('/GetUserVisits', options).then((response) => {
        let { data } = response;
        //console.log(response);
        dispatch({ type: USER_VISITS, payload: data });
    }).catch(err => {
        let { data } = err.response;
        //console.log(data);
        //data.actionType = actionTypes.ERROR
    });
};


export const deleteVisit = (visitId) => async (dispatch, getState) => {
    try {
        const state = getState();
        let token = state.auth.userToken;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
                'visitId': visitId
            }
        };
        const params = {
            "visitId": visitId,
        };
        let deleteVisit = await visits.post('/DeleteVisit', params, options);
        dispatch({ type: SING_OFF_VISIT, payload: visitId });
        toast.success("Udało się wypisać z wizyty.");

        const options2 = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        };

        let userVisits = await visits.get('/GetUserVisits', options2);
        let { data } = userVisits;
        dispatch({ type: USER_VISITS, payload: data });

    } catch (err) {
        let { response } = err;
        toast.error("Nie udało się wypisać z wizyty");
    }
};


export const deleteVisit2 = (visitId) => async (dispatch, getState) => {
    const state = getState();
    let token = state.auth.userToken;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
            'visitId': visitId
        }
    };
    const params = {
        "visitId": visitId,
    };

    visits.post('/DeleteVisit', params, options).then((response) => {
        let { data } = response;
        console.log(data);
        dispatch({ type: SING_OFF_VISIT, payload: visitId });
        toast.success("Udało się wypisać z wizyty.");
        //toast.info("Informacja o wizycie została wysłana na Twoją skrzynkę odbiorczą");
    }).catch(err => {
        console.log(err);
        toast.error("Nie udało się wypisać z wizyty");
    });
};




export const saveVisit2 = (visitId) => async (dispatch, getState) => {
    const state = getState();
    let token = state.auth.userToken;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
            'visitId': visitId
        }
    };

    const params = {
        "visitId": visitId,
    };


    visits.post('/SignUpVisit', params, options).then((response) => {
        let { data } = response;
        dispatch({ type: SING_UP_VISIT, payload: visitId });
        toast.success("Udało się zapisać na wizytę.");
        toast.info("Informacja o wizycie została wysłana na Twoją skrzynkę odbiorczą");
    }).catch(err => {
        toast.error("Nie udało się zapisać na wizytę");
    });
};
