import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import Form from "./Form";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


class Contact extends Component {
    render() {
        return (
            <section className="contact-area" id="contact">
                <div className="container">
                    <div className="row row-reverse">
                        <React.Fragment>
                            <div className="col-md-5">
                                <Reveal effect="fadeInLeft" duration={500}>
                                    <div className="contact_info">
                                        <h4><FormattedMessage id="contact.info.header" defaultMessage="" /></h4>
                                        <ul className="nav">
                                            <li className="item">
                                                <div className="media">
                                                    <a href="/#" style={{marginRight: '5px'}}>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                    </a>
                                                    <div>
                                                        <b><FormattedMessage id="contact.info.address" defaultMessage="" /></b>
                                                    </div>  
                                                </div>
                                                <FormattedMessage id="contact.info.address.1" defaultMessage="" />
                                                <p><FormattedMessage id="contact.info.address.2" defaultMessage="" /></p>
                                            </li>
                                            <li className="item">
                                                <div className="media">
                                                    <a href="/#" style={{ marginRight: '5px' }}>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </a>
                                                    <div>
                                                        <b><FormattedMessage id="contact.info.email" defaultMessage="" /></b>
                                                    </div>
                                                </div>
                                                <p><FormattedMessage id="contact.info.email.1" defaultMessage="" /></p>
                                            </li>
                                            <li className="item">
                                                <div className="media">
                                                    <a href="/#" style={{ marginRight: '5px' }}>
                                                        <FontAwesomeIcon icon={faPhone} />
                                                    </a>
                                                    <div>
                                                        <b><FormattedMessage id="contact.info.phone" defaultMessage="" /></b>
                                                    </div>
                                                </div>
                                                <p><FormattedMessage id="contact.info.phone.1" defaultMessage="" /></p>
                                            </li>
                                        </ul>
                                    </div>
                                </Reveal>
                            </div>
                        </React.Fragment>
                        <div className="col-md-7">
                            <Reveal effect="fadeInRight" duration={800}>
                                <div className="input_form">
                                    <h4><FormattedMessage id="contact.form.header" defaultMessage="" /></h4>
                                    <Form />
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Contact;


