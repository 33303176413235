import React from 'react';
import news from './newsApis';
import history from '../../history';
import { GET_ALL_NEWS } from './newsActionTypes';
//import * as errorHandlerActions from './authErrorHandlerActions';
//import { toast } from "react-toastify";


export const getNewsList = () => async (dispatch, getState) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

    news.get('/GetNewsList', options).then((response) => {
        let { data } = response;
        dispatch({ type: GET_ALL_NEWS, payload: data });
    }).catch(err => {
        //console.log(data);
    });
};



