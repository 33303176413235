// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

//MM

import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { logIn, register } from '../../store/auth';

import { FormattedMessage } from 'react-intl';




class AccountPageRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            EmailErr: false,
            UserName: '',
            UserNameErr: false,
            LastName: '',
            LastNameErr: false,
            Password: '',
            PasswordErr: false,
            ConfirmPassword: '',
            ConfirmPasswordErr: false,
            PhoneNumber: '',
            PhoneNumberErr: false,
            pageTo: props.location.state || { from: { pathname: '/' } }
        };
        
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        switch (event.target.id) {
            case 'Email':
                this.setState({
                    EmailErr: false
                });
                break;
            case 'UserName':
                this.setState({
                    UserNameErr: false
                });
                break;
            case 'LastName':
                this.setState({
                    LastNameErr: false
                });
                break;
            case 'Password':
                this.setState({
                    PasswordErr: false
                });
                break;
            case 'ConfirmPassword':
                this.setState({
                    ConfirmPasswordErr: false
                });
            case 'PhoneNumber':
                this.setState({
                    PhoneNumberErr: false
                });
                break;
        }
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#fafafa"
    };

    onRegisterSubmit = (event) => {
        event.preventDefault();
       
        if (event.target.checkValidity()) {
            let email = this.state.Email;
            let pass = this.state.Password;
            let passConfirmed = this.state.ConfirmPassword;
            let phone = this.state.PhoneNumber;
            let userName = this.state.UserName;
            let lastName = this.state.LastName;

            console.log(phone);

            if (pass === passConfirmed && email.length > 0 && pass.length > 0 && passConfirmed.length > 0 && phone.length >= 9 && userName.length > 0 && lastName.length > 0) {
                this.props.register(email, pass, passConfirmed, phone, userName, lastName);
            }
            else if (pass !== passConfirmed) {
                this.setState({
                    PasswordErr: true,
                    ConfirmPasswordErr: true
                });
            }
            else {
                this.setState({
                    PasswordErr: pass.length > 0 ? false : true,
                    ConfirmPasswordErr: passConfirmed.length > 0 ? false : true,
                    EmailErr: email.length > 0 ? false : true,
                    PhoneNumberErr: phone.length < 9 ? true : false,
                    LastNameErr: lastName.length > 0 ? false : true,
                    UserNameErr: userName.length > 0 ? false : true,
                });
            }
            return;
        }
    }

    render() {

        const errInputStyle = {
            border: '1px solid red'
        };

        const defaultInputStyle = {
            border: '1px solid #ced4da'
        };

        return (
            <React.Fragment>
              
                <div className="row" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px'
                }}>
                    <div className="col-md-3 d-flex mt-4 mt-md-0">
                        <div className="card flex-grow-1 mb-0">
                            <div className="card-body">
                                <h5 className="card-title text-center"><FormattedMessage id="account.login.register" defaultMessage="" /></h5>
                                <form onSubmit={this.onRegisterSubmit} className="ui form error">
                                    <div className="form-group">
                                        <label htmlFor="Email"><FormattedMessage id="account.login.email" defaultMessage="" /></label>
                                        <input
                                            id="Email"
                                            type="email"
                                            className="form-control"
                                            style={this.state.EmailErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Password"><FormattedMessage id="account.login.password" defaultMessage="" /></label>
                                        <input
                                            id="Password"
                                            type="password"
                                            className="form-control"
                                            style={this.state.PasswordErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ConfirmPassword"><FormattedMessage id="account.login.repeatPass" defaultMessage="" /></label>
                                        <input
                                            id="ConfirmPassword"
                                            type="password"
                                            className="form-control"
                                            style={this.state.ConfirmPasswordErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                        <p><FormattedMessage id="account.register.passMin8" defaultMessage="" /></p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="FirstName"><FormattedMessage id="account.login.firstName" defaultMessage="" /></label>
                                        <input
                                            id="UserName"
                                            type="text"
                                            className="form-control"
                                            style={this.state.UserNameErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="LastName"><FormattedMessage id="account.login.lastName" defaultMessage="" /></label>
                                        <input
                                            id="LastName"
                                            type="text"
                                            className="form-control"
                                            style={this.state.LastNameErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="PhoneNumber"><FormattedMessage id="account.login.phone" defaultMessage="" /></label>
                                        <input
                                            id="PhoneNumber"
                                            type="text"
                                            className="form-control"
                                            style={this.state.PhoneNumberErr ? errInputStyle : defaultInputStyle}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    
                                    <button className="btn btn-primary btn-lg btn-block">
                                        <FormattedMessage id="account.login.register" defaultMessage="" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <div>
                        <div className="card flex-grow-1 mb-md-0">
                            <div className="card-body">
                                Masz konta? <Link className="login" to="/Login">Zaloguj się</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <Link className="login" to="/">Strona główna</Link>
                </div>


            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        err: state.error
    };
};


export default connect(mapStateToProps, { register })(AccountPageRegister);

