import React, { Component } from "react";
//import Recaptcha from 'react-recaptcha';
import { Route, Switch, Router } from "react-router-dom";
import { connect } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*-----pages-----*/
import Home from "./Home";
import { Homefrelencer } from "./Home-frelencer";
import { Creativecarousel } from "./Creative-carousel";
import { NotFound } from "./404";
import AccountPageLogin from './component/account/AccountPageLogin';
import AccountPageRegister from './component/account/AccountPageRegister';
import AccountPageRemindPassword from './component/account/AccountPageRemindPassword';
import AccountPageResetPassword from './component/account/AccountPageResetPassword';
import AccountPageUserPanel from './component/account/AccountPageUserPanel';
import AccountPageEmailConfirmation from './component/account/AccountPageEmailConfirmation';

// application
import languages from './i18n';
import { localeChange } from './store/locale';
import createBrowserHistory from './history';
//third-party
import { IntlProvider } from 'react-intl';

class App extends Component {
    render() {
        const { locale, auth } = this.props;
        const { messages, direction } = languages[locale];
        return (
            <IntlProvider locale={locale} messages={messages}>
                <ToastContainer autoClose={5000} hideProgressBar style={{zIndex: 100000}} />
                <React.Fragment>
                    <Router history={createBrowserHistory}>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/LogIn' component={AccountPageLogin} />
                            <Route exact path='/Register' component={AccountPageRegister} />
                            <Route exact path='/RecoverPass' component={AccountPageRemindPassword} />
                            <Route exact path='/PassConfirmation/:id' component={AccountPageResetPassword} />
                            <Route exact path='/AccountPageUserPanel' component={AccountPageUserPanel} />
                            <Route exact path='/ConfirmEmail/:id' component={AccountPageEmailConfirmation} />
                            <Route path="/Home-frelencer" component={Homefrelencer} />
                            <Route path="/Creative-carousel" component={Creativecarousel} />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    auth: state.auth
});


const mapDispatchToProps = {
    localeChange,
};

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);





 //<Route exact path='/AccountPageUserPanel' component={AccountPageUserPanel} />



//<IntlProvider locale={locale} messages={messages}>
//    <ToastContainer autoClose={5000} hideProgressBar />
//    <React.Fragment>
//        <Router>
//            <Switch>
//                <Route exact path='/' component={Home} />
//                <Route exact path='/LogIn' component={AccountPageLogin} />
//                <Route exact path='/Register' component={AccountPageRegister} />
//                <Route exact path='/RecoverPass' component={AccountPageRemindPassword} />
//                <Route exact path='/PassConfirmation/:id' component={AccountPageResetPassword} />
//                <Route exact path='/AccountPageUserPanel' component={AccountPageUserPanel} />
//                <Route path="/Home2" component={Home2} />
//                <Route path="/Home3" component={Home3} />
//                <Route path="/Home4" component={Home4} />
//                <Route path="/Home5" component={Home5} />
//                <Route path="/Home6" component={Home6} />
//                <Route path="/Home-frelencer" component={Homefrelencer} />
//                <Route path="/Creative-carousel" component={Creativecarousel} />
//                <Route component={NotFound} />
//            </Switch>
//        </Router>
//    </React.Fragment>
//</IntlProvider>



//class App extends Component {
//    render() {
//        const { locale, auth } = this.props;
//        return (
//            <React.Fragment>
//                <Router>
//                    <Switch>
//                        <Route exact path="/" component={Home} />
//                        <Route path="/Home2" component={Home2} />
//                        <Route path="/Home3" component={Home3} />
//                        <Route path="/Home4" component={Home4} />
//                        <Route path="/Home5" component={Home5} />
//                        <Route path="/Home6" component={Home6} />
//                        <Route path="/Home-frelencer" component={Homefrelencer} />
//                        <Route path="/Creative-carousel" component={Creativecarousel} />
//                        <Route component={NotFound} />
//                    </Switch>
//                </Router>
//            </React.Fragment>
//        );
//    }
//}