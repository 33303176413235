import React from 'react';
import { connect } from 'react-redux';
//import { Field, reduxForm } from 'redux-form';
//import Avatar from '@material-ui/core/Avatar';
//import CssBaseline from '@material-ui/core/CssBaseline';
//import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
//import Typography from '@material-ui/core/Typography';
//import Container from '@material-ui/core/Container';
//import { confirmEmail } from '../../actions/User';
import { confirmEmail } from '../../store/auth';
//import { makeStyles } from '@material-ui/core/styles';

//import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
// data stubs
//import theme from '../../data/theme';
//const useStyles = makeStyles((theme) => ({
//    paper: {
//        marginTop: theme.spacing(8),
//        display: 'flex',
//        flexDirection: 'column',
//        alignItems: 'center',
//    },
//    avatar: {
//        margin: theme.spacing(1),
//        backgroundColor: theme.palette.success.main,
//    },
//    form: {
//        width: '100%', // Fix IE 11 issue.
//        marginTop: theme.spacing(1),
//    },
//    submit: {
//        margin: theme.spacing(3, 0, 2),
//    }
//}));

class AccountPageEmailConfirmation extends React.Component {



    componentDidMount() {
        document.body.style.backgroundColor = "#fafafa"
        let { id } = this.props.match.params;
        this.props.confirmEmail(id);
    }


    render() {
        let title;
        let message;
        if (this.props.isConfirmed) {
            title = 'Adres e-mail jest poprawny';
            message = 'Email potwierdzony, możesz zalogować się na swoje konto!';
        }
        else {
            title = 'Adres e-mail NIE jest poprawny';
            message = 'Przepraszamy konto nie istnieje';
        }

        return (
            <React.Fragment>
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '40px' }}>
                    <div className="col-md-3 d-flex" style={{ textAlign: 'center' }}>
                        <div className="card flex-grow-1 mb-md-0">
                            <div className="card-body">
                                <div className="container">
                                    <div className="not-found">
                                        <div className="not-found__404">
                                            {title}
                                        </div>

                                        <div className="not-found__content">
                                            <p className="not-found__text">
                                                {message}
                                            </p>

                                            <Link to="/" className="btn btn-primary btn-sm" >Strona główna</Link>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        isConfirmed: state.auth.isConfirmed
    };
};

export default connect(mapStateToProps, { confirmEmail })(AccountPageEmailConfirmation);




//<div className="block">
//    <div className="container">
//        <div className="not-found">
//            <div className="not-found__404">
//                {title}
//            </div>

//            <div className="not-found__content">
//                <p className="not-found__text">
//                    {message}
//                </p>

//                <Link to="/" className="btn btn-secondary btn-sm">Strona główna</Link>
//            </div>
//        </div>
//    </div>
//</div>