import _ from 'lodash'; 
import { GET_ALL_NEWS } from './newsActionTypes';

const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
    //console.log(action.payload);
    switch (action.type) {
        case GET_ALL_NEWS:
            return { ...state, newsList: action.payload }
        default:
            return state;
    }
};