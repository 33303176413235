// react
import React from 'react';

import { Link } from 'react-router-dom';

//MM
import { connect } from 'react-redux';
import { getVisitsList, getHoursList, saveVisit } from '../../store/visits';

import _ from 'lodash';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { toast } from "react-toastify";

class AccountPageUserPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.getVisitsList();
    };


    saveVisit = (id) => {
        if (this.props.isSignedIn) {
            this.props.saveVisit(id);
        }
        else {
            toast.info("Zaloguj się aby zapisać się na wizytę.");
        }
    };

    handleDayClick = (day, { selected }) => {
        this.props.getHoursList(day);
    }

    render() {
        const value = this.props.visitsList;
        const dateList = value.map((d) => new Date(d));
        const initialYear = new Date().getFullYear();
        const initialMonth = new Date().getMonth();

        const btnList = this.props.hoursList;

        return (
            <React.Fragment>
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '40px' }}>
                    <div className="col-md-3 d-flex">
                        <div className="card flex-grow-1 mb-md-0">
                            <div className="card-body" style={{ lineHeight: '20px' }}>
                                <DayPicker initialMonth={new Date(initialYear, initialMonth)} selectedDays={dateList} onDayClick={this.handleDayClick} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex">
                        <div className="card flex-grow-1 mb-md-0">
                            <div className="card-body">
                                <div>
                                    {btnList.map((btn) => btn.reserved === true ? <button key={btn.id} type="button" class="btn btn-light m-1" disabled>{btn.timeStarts}</button> : <button key={btn.id} type="button" onClick={(e) => this.saveVisit(btn.id, e)} class="btn btn-outline-success m-1">{btn.timeStarts}</button> )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <Link className="login" to="/">Strona główna</Link>
                </div>
            </React.Fragment>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        err: state.error,
        visitsList: _.values(state.visits.visitsList),
        hoursList: _.values(state.visits.hoursList)
    };
};


export default connect(mapStateToProps, { getVisitsList, getHoursList, saveVisit })(AccountPageUserPanel);


