
import { SIGN_IN, SIGN_OUT, CHANGE_AUTH, REGISTER_REQUEST, GET_USER_TOKEN, LOG_OUT, LOGIN_REQUEST, AUTHENTICATE_THE_USER, SET_USER_ID, USER_IS_CONFIRMED } from './authActionTypes';

const INITIAL_STATE = {
    isSignedIn: null,
    userToken: null,
    tokenExpiration: null,
    userId: null,
    userEmail: null,
    userImg: null,
    userName: null,
    isConfirmed: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATE_THE_USER:
            return { ...state, isSignedIn: action.payload.isSignedIn }
        case CHANGE_AUTH:
            return { ...state, isSignedIn: action.payload.isSignedIn, userId: action.payload.googleId }
        case LOGIN_REQUEST:
            return { ...state, isSignedIn: true, userToken: action.payload.token, tokenExpiration: action.payload.expiration, userName: action.payload.userName }
        case REGISTER_REQUEST:
            return { ...state, isSignedIn: true, userToken: action.payload.token, tokenExpiration: action.payload.expiration, userName: action.payload.userName }
        case SET_USER_ID:
            return { ...state, userId: action.payload.data }
        case LOG_OUT:
            return { ...state, isSignedIn: false, userToken: null, tokenExpiration: null, userName: null, userId: null }
        case USER_IS_CONFIRMED:
            return { ...state, isConfirmed: action.payload }
        default:
            return state;
    }
};