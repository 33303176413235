import React, { Component } from 'react';
import { Fade } from 'react-reveal/';
import aboutImg from '../image/app/about1.png';
import { FormattedMessage } from 'react-intl';

class About extends Component {
    render() {
        return (
            <div>
                <div id="about">
                    <Fade bottom cascade duration={1000}>
                        <h1 className="card-tittle">O mnie</h1>
                        <p className="paragraph"><FormattedMessage id="about.description1.1" defaultMessage="" /><FormattedMessage id="about.description1.2" defaultMessage="" /><FormattedMessage id="about.description1.3" defaultMessage="" />
                        </p>
                    </Fade>
                </div>
                <div class="image-box">
                    <img src={aboutImg} alt="Logo" style={{ backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", color: "#f5f5f5"}} />;
                </div>
            </div>
        )
    }
}
export default About;

