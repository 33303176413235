import { combineReducers } from 'redux';
//MM
import { reducer as formReducer } from 'redux-form';

// reducers
//import cartReducer from './cart';
//import compareReducer from './compare';
//import currencyReducer from './currency';
import localeReducer from './locale';
//import mobileMenuReducer from './mobile-menu';
//import quickviewReducer from './quickview';
//import sidebarReducer from './sidebar';
//import version from './version';
//import wishlistReducer from './wishlist';
import visitsReducer from './visits';
import newsReducer from './news';

//MM
import authReducer from './auth';
//import accountReducer from './account';
//import errorHandlerReducer from './error';
//import addressReducer from './address';

const appReducer = combineReducers({
    locale: localeReducer,
    auth: authReducer,
    form: formReducer,
    visits: visitsReducer,
    news: newsReducer
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    //if (action.type === 'LOG_OUT') {
    //    state = undefined;
    //}

    return appReducer(state, action);
};

export default rootReducer;


