import React , {Component} from 'react';

export class MapContainer extends Component {
    //constructor(props) {
    //  super(props);
    //}
  
    render() {
        const mapStyles = {
            width: '100%',
            height: '500px',
        };
        return (
            <section className="contact_map">
                <iframe frameBorder="0" style={{ width: "100%", height: "100%" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d625.9684035227274!2d17.040727829265368!3d51.12923924035494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe9c1b575e861%3A0xaf341b60c5314752!2sZak%C5%82adowa%207cf%2C%2050-231%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1632851802818!5m2!1spl!2spl"></iframe>
            </section> 
      );
    }
  }

export default MapContainer;
