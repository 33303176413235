// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

//MM
import { connect } from 'react-redux';
import { logIn } from '../../store/auth';

import { FormattedMessage } from 'react-intl';


class AccountPageLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail: '',
            loginEmailErr: false,
            loginPassword: '',
            loginPasswordErr: false,
            pageTo: props.location.state || { from: { pathname: '/' } }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            loginEmail: event.target.value,
            loginEmailErr: false
        });
    }
    handlePassChange(event) {
        this.setState({
            loginPassword: event.target.value,
            loginPasswordErr: false
        });
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#fafafa"
    };

    onSubmit = (event) => {
        event.preventDefault();
        if (event.target.checkValidity()) {
            // form is valid! We can parse and submit data
            let email = this.state.loginEmail;
            let pass = this.state.loginPassword;
            if (email.length > 0 && pass.length > 0) {
                const { pathname } = this.state.pageTo.from;
                this.props.logIn(email, pass);
            }
            else {
                this.setState({ loginEmailErr: email.length > 0 ? false : true });
                this.setState({ loginPasswordErr: pass.length > 0 ? false : true });
            }
            return;
        }
    }

    render() {
        var { mClass, mContainer, mainlogo, stickylogo } = this.props;
        const errInputStyle = {
            border: '1px solid red'
        };

        const defaultInputStyle = {
            border: '1px solid #ced4da'
        };

        return (
            <React.Fragment>

                
                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '40px' }}>

                        <div className="col-md-3 d-flex">

                            <div className="card flex-grow-1 mb-md-0">

                                <div className="card-body">
                                    <h5 className="card-title text-center"><FormattedMessage id="account.login.login" defaultMessage="" /></h5>
                                    <form onSubmit={this.onSubmit} className="ui form error">
                                        <div className="form-group">
                                            <label htmlFor="login-email"><FormattedMessage id="account.login.email" defaultMessage="" /></label>
                                            <input
                                                id="loginEmail"
                                                type="email"
                                                className="form-control"
                                                style={this.state.loginEmailErr ? errInputStyle : defaultInputStyle}
                                                placeholder="Adres email"
                                                onChange={this.handleInputChange}
                                                autoComplete="on" 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password"><FormattedMessage id="account.login.password" defaultMessage="" /></label>
                                            <input
                                                id="loginPassword"
                                                type="password"
                                                className="form-control"
                                                style={this.state.loginPasswordErr ? errInputStyle : defaultInputStyle}
                                                placeholder="Hasło"
                                                onChange={this.handlePassChange}
                                            />
                                            <small className="form-text text-muted">
                                                <Link to="/RecoverPass"><FormattedMessage id="account.login.forgetPassword" defaultMessage="" /></Link>
                                            </small>
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block">
                                            <FormattedMessage id="account.login.loginBtn" defaultMessage="" />
                                        </button>
                                    </form>
                                </div>

                            </div>

                        </div>


                    </div>



                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                        <div>
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    Nie masz konta? <Link className="login" to="/Register">Zarejestruj się</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                        <Link className="login" to="/">Strona główna</Link>
                    </div>



            </React.Fragment>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        err: state.error
    };
};


export default connect(mapStateToProps, { logIn })(AccountPageLogin);



