import _ from 'lodash'; 
import { GET_ALL_VISITS, GET_VISITS_HOURS, SING_UP_VISIT, SING_OFF_VISIT, USER_VISITS } from './visitsActionTypes';

//const INITIAL_STATE = []

const INITIAL_STATE = {
    hoursList: [],
    visitsList: [],
    usersVisits: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_VISITS:
            return { ...state, visitsList: action.payload }
        case GET_VISITS_HOURS:
            return { ...state, hoursList: action.payload }
        case SING_UP_VISIT:
            return { ...state, hoursList: state.hoursList.map(item => item.id === action.payload ? {...item, reserved: true } : item) }
        case SING_OFF_VISIT:
            return { ...state, hoursList: state.hoursList.map(item => item.id === action.payload ? { ...item, reserved: false } : item) }
        case USER_VISITS:
            return { ...state, usersVisits: action.payload }
        default:
            return state;
    }
};