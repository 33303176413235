import React, { Component } from 'react';
import { Reveal } from 'react-reveal/';
import logo from '../../image/app/logo.png';
import logoGinVita from '../../image/app/GinVita.png';

import { Link as ScrollLink } from "react-scroll";
import { FormattedMessage } from 'react-intl';

const image_2 = require('../../image/app/glowny1.jpg');
class Banner extends Component {
    
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        return (
            <section id="home">
                <div className="banner_area" style={{
                    backgroundImage: 'url(' + image_2 + ')',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    color: "#f5f5f5"
                }}>
                    <div className="container">
                        <div className="banner_content">
                            <Reveal effect="fadeInUp" duration={1500}>
                                <div>
                                    <img src={logo} alt="Logo" style={{ height: "15vh" }} />
                                </div>
                            </Reveal>
                            <br />
                            <Reveal effect="fadeInUp" duration={1500}>
                            <div className="NewContact">
                                <ScrollLink
                                        to="contact_map"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="baner.newAddress" defaultMessage="" />
                                    </ScrollLink>
                            </div>
                            </Reveal>
                            <br />
                            <Reveal effect="fadeInUp" duration={1500}>
                                <button type="button" className="btn btn-outline-light" onClick= {() => this.openInNewTab('https://ginvita.pl/index.html')}>
                                    <img src={logoGinVita} alt="Logo" style={{ height: "5vh" }} />
                                </button>
                            </Reveal>
                      </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Banner;

