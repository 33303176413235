// application
import enMessages from './messages/en.json';
import plMessages from './messages/pl.json';

export default {
    pl: {
        messages: plMessages,
        direction: 'ltr',
    },
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
};