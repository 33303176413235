import React from 'react';
import TestimonialSlider from './TestimonialSlider';
import { Parallax } from 'react-parallax';
import Sectiontitle from '../Banner/Sectiontitle';
import { FormattedMessage } from 'react-intl';
import newsImg from '../../image/app/news.jpg';

const Testimonial = () => {
    return (
        <section id="testimonial">
            <div className="testimonial_area" style={{
                            backgroundImage: 'url(' + newsImg + ')',
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                            color: "#f5f5f5"
                        }}>
                
                <h1 className="card-tittle" style={{ color: "#f5f5f5" }}><FormattedMessage id="news.header" defaultMessage="" /></h1>
                <div className="container">
                    <div className="work_item wow">
                        <TestimonialSlider />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Testimonial;


//<section id="testimonial">
//    <Parallax bgImage={require('../../image/app/news.jpg')} strength={350} className="testimonial_area">

//        <h1 className="card-tittle"><FormattedMessage id="news.header" defaultMessage="" /></h1>
//        <div className="container">
//            <div className="work_item wow">
//                <TestimonialSlider />
//            </div>
//        </div>
//    </Parallax>
//</section>


//<section id="testimonial">
//    <Parallax bgImage={require('../../image/portfolio/img-3.jpg')} strength={280} className="testimonial_area">
//        <div className="container">
//            <TestimonialSlider />
//        </div>
//    </Parallax>
//</section>


//<Sectiontitle Title={<FormattedMessage id="news.header" defaultMessage="" />} />


