import { LOCALE_CHANGE } from './localeActionTypes';

//const initialState = 'en';
const initialState = 'pl';

export default function localeReducer(state = initialState, action) {
    if (action.type === LOCALE_CHANGE) {
        //return action.locale;
        return action.payload;
    }

    return state;
}
