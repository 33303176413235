import React from 'react';
import { connect } from 'react-redux';
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from 'react-intl';


import Navbar from './component/Navbar/Navbar';
import Banner from './component/Banner/Banner';
import About from './component/About';
import Service from './component/Service';
import Testimonial from './component/Testimonial/Testimonial';
import MapContainer from './component/Map';
import Contact from './component/Contact';
import Footer from './component/Footer';
import jhonData from './component/jhonData';
import AccountPageUserPanel_2 from './component/account/AccountPageUserPanel_2';
import AccountPageUserVisits from './component/account/AccountPageUserVisits';


class Home extends React.Component {
    render() {
        return (
            <div className="body_wrapper">
                <Navbar mContainer="custome_container" mainlogo="logo.png" stickylogo="logo_dark.png" />
                <Banner jhonData={jhonData} />
                <Testimonial />
                <Service wClass="work_area" jhonData={jhonData} />
                <About aClass="about_area" jhonData={jhonData} />
                <MapContainer />
                <Contact jhonData={jhonData} />
                <Footer jhonData={jhonData} />

                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"><FormattedMessage id="modal.header.1" defaultMessage="" /></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AccountPageUserPanel_2 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-user-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"><FormattedMessage id="modal.header.2" defaultMessage="" /></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.isSignedIn && (
                                    <AccountPageUserVisits />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <CookieConsent
                    location="bottom"
                    buttonText="Zgoda"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                ><FormattedMessage id="cookie.content" defaultMessage="" />{" "}
                   
                </CookieConsent>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    isSignedIn: state.auth.isSignedIn,
});


export default connect(mapStateToProps)(Home);



