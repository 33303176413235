import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import logo from '../image/app/logo.png';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

class Footer extends Component {
    render() {
        return (
            <section className="footer-area">
                <div className="container">
                    <div>
                        <div className="row footer-content">
                            <div className="col-lg-3 col-sm-3" >
                                <Fade top cascade>
                                    <div>
                                        <img src={logo} alt="Logo" style={{ height: "10vh" }} />
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-4 col-sm-4">
                                <ul style={{ color: "#f5f5f5" }}>
                                    <li><FormattedMessage id="services.secondary.1" defaultMessage="" /></li>
                                    <li><FormattedMessage id="services.secondary.2" defaultMessage="" /></li>
                                    <li><FormattedMessage id="services.secondary.3" defaultMessage="" /></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-sm-2">
                                <ul style={{ color: "#f5f5f5" }}>
                                    <li><FormattedMessage id="services.secondary.4" defaultMessage="" /></li>
                                    <li><FormattedMessage id="services.secondary.5" defaultMessage="" /></li>
                                    <li><FormattedMessage id="services.secondary.6" defaultMessage="" /></li>
                                    <li><FormattedMessage id="services.secondary.7" defaultMessage="" /></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-sm-3">
                                <ul style={{ color: "#f5f5f5", listStyleType: "none" }}>
                                    <li><FontAwesomeIcon icon={faMapMarkerAlt} /> <FormattedMessage id="contact.info.address.1" defaultMessage="" /></li>
                                    <li><FontAwesomeIcon icon={faEnvelope} /> <FormattedMessage id="contact.info.email.1" defaultMessage="" /></li>
                                    <li><FontAwesomeIcon icon={faPhone} /> <FormattedMessage id="contact.info.phone.1" defaultMessage="" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Footer;

