import auth from './authApis';
import history from '../../history';
import * as actionTypes from './authActionTypes';
import { toast } from "react-toastify";


export const logIn = (email, pass) => async (dispatch, getState) => {
    try {
        const config = { 'Content-Type': 'application/json', };
        const userName = email;

        const params = {
            "Email": email,
            "Password": pass
        };

        let currToken = await auth.post('/LoginUser', params, config);
        let { token } = currToken.data.value;
        let { expiration } = currToken.data.value;
        dispatch({ type: actionTypes.LOGIN_REQUEST, payload: { token, expiration, userName } });

        const options = {
            //headers: { Authorization: "Bearer " + token }
            headers: { Authorization: token }
        };
        const bodyParameters = {
            key: "value"
        };
        let currUser = await auth.post('/GetUserIdByJWT', bodyParameters, options);
        let { data } = currUser;

        dispatch({ type: actionTypes.SET_USER_ID, payload: { data } });
        
        history.push('/');
    }
    catch (err) {
        let { response } = err;

        toast.error(response.data);

    }
};

export const logOut = (props) => async dispatch => {
    const config = { 'Content-Type': 'application/json' };
    auth.post('/LogOutUser', config).then((response) => {
        dispatch({ type: actionTypes.LOG_OUT });
        history.push('/');
    }).catch(err => {
        //dispatch(errorHandlerActions.handleHTTPError(err, props));
    });
};

export const register = (email, pass, passConfirmed, phone, userName, lastName) => async dispatch => {

    const params = {
        "Email": email,
        "Password": pass,
        "Password": passConfirmed,
        "PhoneNumber": phone,
        "UserName": userName,
        "LastName": lastName
    };

    auth.post('/RegisterUser', params).then((response) => {
        const currEmail = email;
        let { data } = response;
        dispatch({ type: actionTypes.REGISTER_REQUEST, payload: { response, currEmail } });
        toast.success(response.data);
    }).catch(err => {
        let { response } = err;
        toast.error(response.data);
    });
};

export const sendResetPassEmail = (email) => async dispatch => {
    const config = { 'Content-Type': 'application/json' };
    const params = {
        "Email": email,
    };

    auth.post('/SendNewPasswordEmail', params, config).then((response) => {
        let { data } = response;
        toast.success(response.data);
    }).catch(err => {
        let { response } = err;
        toast.error(response.data);
    });
};

export const forgotPassword = (formValues) => async dispatch => {
    const config = { 'Content-Type': 'application/json' };
    auth.post('/ForgotPassword', formValues, config).then((response) => {
        let { data } = response;
    }).catch(err => {
        let { data } = err.response;
    });
};

export const confirmResetPass = (password, id) => async dispatch => {
    const config = { 'Content-Type': 'application/json' };

    const params = {
        "Password": password,
        "token": id
    };

    auth.post('ConfirmRefreshedPassword', params, config).then((response) => {
        let { data } = response;
        toast.success(response.data);
    }).catch(err => {
        let { response } = err;
        toast.error(response.data);
    });
};

export const confirmEmail = (id) => async dispatch => {
    const config = { 'Content-Type': 'application/json' };

    const options = {
        headers: { token: id }
    };
    const bodyParameters = {
        key: "value"
    };

    auth.post('/ConfirmEmail', bodyParameters, options).then((response) => {

        dispatch({ type: actionTypes.USER_IS_CONFIRMED, payload: response });

    }).catch(err => {
        let { response } = err;
    });
};