import React, { Component } from "react";
import Sticky from "react-stickynode";
import { Link as ScrollLink } from "react-scroll";
import { connect } from 'react-redux';
import { Link as SiteLink } from 'react-router-dom';

//application
import { localeChange } from '../../store/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faSignInAlt, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { logOut } from '../../store/auth/authActions';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        }
    }
    
    handleLanguageChange = (x) => {
        this.props.localeChange(x);
    }

    handleLogOut = (x) => {
        this.props.logOut();
    }

    handleMenuClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    //handleLinkClick() {
    //    this.setState({ menuOpen: false });
    //}

    render() {
        var { mClass, mContainer, mainlogo, stickylogo } = this.props;
        
        return (
            <Sticky top={0} innerZ={100} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
                    <div className={`container ${mContainer}`}>
                        <a className="navbar-brand logo_h" href="#">
                            <img src={require("../../image/app/" + mainlogo)} alt="" style={{ height: "7vh" }} />
                            <img src={require("../../image/app/" + stickylogo)} alt="" style={{ height: "7vh" }} />
                        </a>
                        <div className="row">
                            {/*
                             * //SMALL
                            <div class="col-sm">
                                <div class="dropdown">
                                    <button className="btn btn-warning dropdown-toggle d-lg-none btn-sm" style={{ zIndex: '1' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tooltip="Zmiana j�zyka">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <span className="dropdown-item" onClick={() => this.handleLanguageChange('pl')}>PL</span>
                                        <span className="dropdown-item" onClick={() => this.handleLanguageChange('en')}>ENG</span>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="col-sm">
                                <button type="button" className="btn btn-warning btn-sm d-lg-none" data-toggle="modal" data-target=".bd-example-modal-lg">
                                    Wizyty
                                </button>
                            
                                {!this.props.isSignedIn && (
                                    <SiteLink to="/LogIn" style={{ zIndex: '1' }}>
                                        <button className=" btn btn-primary btn-sm d-lg-none">
                                        Zaloguj
                                        </button>
                                    </SiteLink>
                                )}
                                {this.props.isSignedIn && (
                                    <button type="button" className="btn btn-warning btn-sm d-lg-none" data-toggle="modal" data-target=".bd-user-modal-lg">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </button>
                                )}
                                {this.props.isSignedIn && (
                                    <button className=" btn btn-danger btn-sm d-lg-none" onClick={this.handleLogOut}>
                                        Wyloguj
                                    </button>
                                )}
                            </div>
                        </div>


                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded= {this.state.menuOpen}
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>

                        <div className={"collapse navbar-collapse offset " + this.state.menuOpen} id="navbarSupportedContent">
                            <ul className="nav navbar-nav m-auto">
                                <li className="nav-item">
                                    <ScrollLink
                                        className="nav-link"
                                        activeClass="active"
                                        to="home"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="navbar.menu.home" defaultMessage="" />
                                    </ScrollLink>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink
                                        className="nav-link"
                                        activeClass="active"
                                        to="testimonial"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="navbar.menu.testimonial" defaultMessage="" />
                                    </ScrollLink>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink
                                        className="nav-link"
                                        activeClass="active"
                                        to="service"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="navbar.menu.service" defaultMessage="" />
                                    </ScrollLink>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink
                                        className="nav-link"
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="navbar.menu.about" defaultMessage="" />
                                    </ScrollLink>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink
                                        className="nav-link"
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                        onClick={this.handleMenuClick}
                                    >
                                        <FormattedMessage id="navbar.menu.contact" defaultMessage="" />
                                    </ScrollLink>
                                </li>
                            </ul>

                            {/*
                             * 
                             * {{!this.state.menuOpen ? 'show' : ''
                             * 
                            <ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
                                <li className="nav-item">
                                    <div class="dropdown">
                                        <button className="btn btn-warning dropdown-toggle btn-sm" style={{ zIndex: '1' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tooltip="Zmiana j�zyka">
                                            <FontAwesomeIcon icon={faGlobe} />
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <span className="dropdown-item" onClick={() => this.handleLanguageChange('pl')}>PL</span>
                                            <span className="dropdown-item" onClick={() => this.handleLanguageChange('en')}>ENG</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                             */}

                            {
                                <ul className="nav navbar-nav navbar-right d-sm-none d-lg-block">
                                <li className="nav-item">
                                        <button type="button" className="btn btn-warning" data-toggle="modal" data-target=".bd-example-modal-lg" style={{ zIndex: '1' }}>
                                        Wizyty
                                    </button>
                                </li>
                            </ul>
                             }
                            {this.props.isSignedIn && (
                            <ul className="nav navbar-nav navbar-right d-sm-none d-lg-block">
                                <li className="nav-item">
                                    
                                        <button type="button" className="btn btn-warning" data-toggle="modal" data-target=".bd-user-modal-lg">
                                            <FontAwesomeIcon icon={faUserCircle} />
                                        </button> 
                                    
                                </li>
                            </ul>
                            )}
                            <ul className="nav navbar-nav navbar-right d-sm-none d-lg-block">
                                <li className="nav-item">
                                    {!this.props.isSignedIn && (
                                        <SiteLink to="/LogIn">
                                            <button className="btn btn-primary btn-sm d-lg">
                                                Zaloguj
                                            </button>
                                        </SiteLink>
                                    )}
                                    {this.props.isSignedIn && (

                                        <button className=" btn btn-danger btn-sm d-lg" onClick={this.handleLogOut}>
                                            Wyloguj
                                            </button>

                                    )}
                                  
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </Sticky>
        );
    }
}
//<FontAwesomeIcon icon={faSignInAlt} />
//<FontAwesomeIcon icon={faSignInAlt} />

const mapStateToProps = (state) => ({
    locale: state.locale,
    isSignedIn: state.auth.isSignedIn,
});

const mapDispatchToProps = {
    localeChange,
    logOut
};
//<FontAwesomeIcon icon={faUserCircle} />

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);




//<SiteLink to="/LogOut">
//    <button className=" btn btn-danger  btn-sm d-lg-none">
//        <FontAwesomeIcon icon={faSignInAlt} />
//    </button>
//</SiteLink>
















//<ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
//    <li className="nav-item">
//        <button type="button" class="btn btn-warning" data-toggle="modal" data-target=".bd-example-modal-lg">
//            <FontAwesomeIcon icon={faUserCircle} />
//        </button>
//    </li>
//</ul>



//<a className="navbar-brand logo_h" href="index.html">
//    <img src={require("../../image/" + mainlogo)} alt="" />
//    <img src={require("../../image/" + stickylogo)} alt="" />
//</a>

//export default Navbar;


//<ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
//    <li className="nav-item">
//        <SiteLink className="login" to="/Register">Zarejestruj</SiteLink>
//    </li>
//</ul>



//<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
//    <a className="dropdown-item" href="#" onClick={() => this.handleLanguageChange('pl')}>PL</a>
//    <a className="dropdown-item" href="#" onClick={() => this.handleLanguageChange('en')}>ENG</a>
//</div>






        //  <li className="nav-item">
        //                        <ScrollLink
        //            className="nav-link"
        //            activeClass="active"
        //            to="portfolio"
        //            spy={true}
        //            smooth={true}
        //            offset={-86}
        //            duration={500}
        //          >
        //Portfolio
        //          </ScrollLink>
        //        </li>
        //        <li className="nav-item">
        //                        <ScrollLink
        //            className="nav-link"
        //            activeClass="active"
        //            to="testimonial"
        //            spy={true}
        //            smooth={true}
        //            offset={-86}
        //            duration={500}
        //          >
        //    Testimonial
        //          </ScrollLink>
        //        </li>
        //        <li className="nav-item">
        //                        <ScrollLink
        //            className="nav-link"
        //            activeClass="active"
        //            to="blog"
        //            spy={true}
        //            smooth={true}
        //            offset={-86}
        //            duration={500}
        //          >
        //    Blog
        //          </ScrollLink>
        //</li >