// react
import React from 'react';

import { Link } from 'react-router-dom';


//MM
import { connect } from 'react-redux';
import { sendResetPassEmail } from '../../store/auth';

import { FormattedMessage } from 'react-intl';

class AccountPageRemindPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            EmailErr: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        switch (event.target.id) {
            case 'Email':
                this.setState({
                    EmailErr: false
                });
                break;
         }
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#fafafa"
    };

    onResetSubmit = (event) => {
        event.preventDefault();
        let email = this.state.Email;
        this.props.sendResetPassEmail(email);
    }

    render() {

        const errInputStyle = {
            border: '1px solid red'
        };

        const defaultInputStyle = {
            border: '1px solid #ced4da'
        };

        return (
            <React.Fragment>

                <div className="row" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px'
                }}>
                    <div className="col-md-3 d-flex mt-4 mt-md-0">
                        <div className="card flex-grow-1 mb-0">
                            <div className="card-body">
                                <h5 className="card-title text-center"><FormattedMessage id="account.login.recoverPass" defaultMessage="" /></h5>
                                <form onSubmit={this.onResetSubmit} className="ui form error">
                                    <div className="form-group">
                                        <label htmlFor="Email"><FormattedMessage id="account.login.recoverEmail" defaultMessage="" /></label>
                                        <input
                                            id="Email"
                                            type="email"
                                            className="form-control"
                                            //style={this.state.EmailErr ? errInputStyle : defaultInputStyle}
                                            //placeholder="Password"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <button className="btn btn-primary btn-lg btn-block">
                                        <FormattedMessage id="account.login.recoverPassBtn" defaultMessage="" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <Link className="login" to="/">Strona główna</Link>
                </div>


            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        err: state.error
    };
};


export default connect(mapStateToProps, { sendResetPassEmail })(AccountPageRemindPassword);

