// third-party
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createBrowserHistory from '../history';
// reducer
import rootReducer from './rootReducer';
import version from './version';

function load() {
    let state;

    try {
        state = localStorage.getItem('state');

        if (typeof state === 'string') {
            state = JSON.parse(state);
        }

        if (state && state.version !== version) {
            state = undefined;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return state || undefined;
}

const middleware = [
    thunk,
    routerMiddleware(createBrowserHistory)
];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    //compose(applyMiddleware(...middleware))
    composeEnhancer(applyMiddleware(thunk))
);

export default store;
