import React from "react";
import Reaptcha from 'reaptcha';

import { Field, reduxForm } from 'redux-form';
import { toast } from "react-toastify";
import { FormattedMessage } from 'react-intl';
import userApis from '../store/auth/authApis';

const errInputStyle = {
    border: '1px solid red'
};

const defaultInputStyle = {
    border: '1px solid #ced4da'
};

class Form extends React.Component {
    //***CAPTCHA
    constructor(props) {
        super(props)
        this.state = {
            verified: false
        }
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    renderError({ error, touched }) {
        if (touched && error) {
            return (
                <div className="ui error message">
                    <div className="header">
                        {error}
                    </div>
                </div>
            );
        }
    };

    renderInput = ({ input, label, isDisabled, meta }) => {
        return (
            <div>
                <label htmlFor={input.name}>{label}</label>
                <input
                    id={input.name}
                    name={input.name}
                    type="text"
                    className="form-control"
                    style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
                    onChange={input.onChange}
                    value={input.value}
                />

            </div>
        )
    }

    renderEmail = ({ input, label, isDisabled, meta }) => {
        return (
            <div className="field error">
                <label htmlFor="profile-first-name">{label}</label>
                <input
                    id={input.name}
                    name={input.name}
                    type="email"
                    className="form-control"
                    style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
                    onChange={input.onChange}
                    value={input.value}
                />

            </div>
        )
    }

    renderTextArea = ({ input, label, isDisabled, meta }) => {
        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
        return (
            <div className={className}>
                <label htmlFor="profile-first-name">{label}</label>
                <textarea
                    id={input.name}
                    name={input.name}
                    type="text"
                    className="form-control"
                    style={meta.error && meta.touched ? errInputStyle : defaultInputStyle}
                    onChange={input.onChange}
                    value={input.value}
                />

            </div>
        )
    }

    onSubmit = (formValues) => {
        if (this.state.verified) {
            const options = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            userApis.post('/LeaveUsAMessage', formValues, options).then((response) => {
                toast.success("Wiadomo�� wys�ano, dzi�kujemy!");
            }).catch(err => {
                toast.warn("Co� posz�o nie tak");
            });
        } else {
            toast.info("Prosz� o weryfikacj� �e jeste� cz�owiekiem :)");
        }
    }

    verifyCallback = recaptchaResponse => {
        this.setState({
            verified: true
        });
    };

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }
    }


    render() {

        return (
            <React.Fragment>
                <div className="block">

                    <div className="contact-us__container">
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <Field name="Name" component={this.renderInput} label={<FormattedMessage id="contactUs.name" defaultMessage="Twoje imie" />} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <Field name="Email" component={this.renderEmail} label={<FormattedMessage id="contactUs.email" defaultMessage="Email" />} />
                                        </div>
                                    </div>
                                    <Field name="Subject" component={this.renderInput} label={<FormattedMessage id="contactUs.subject" defaultMessage="Temat" />} />
                                    <Field name="Message" component={this.renderTextArea} label={<FormattedMessage id="contactUs.message" defaultMessage="Wiadomo��" />} />

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <button className="btn send_btn theme_btn">
                                                <FormattedMessage id="contactUs.sendMessage" defaultMessage="Wy�lij wiadomo��" />
                                            </button>
                                        </div>
                                        <div className="form-group col-md-6">
                                            
                                            <Reaptcha sitekey="6LdjCeEZAAAAANlvj4UCeZYqBWGI2sAoG4TGt9IY" onVerify={this.verifyCallback} onloadCallback={this.onLoadRecaptcha} />
                                            
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.Name) {
        errors.Name = 'Error';
    }
    if (!formValues.Email) {
        errors.Email = 'Error';
    }
    if (!formValues.Subject) {
        errors.Subject = 'Error';
    }
    if (!formValues.Message) {
        errors.Message = 'Error';
    }
    return errors;
};

const formWrapped = reduxForm({
    form: 'leaveMessage',
    validate: validate
})(Form);

export default formWrapped;
