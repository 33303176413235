// react
import React from 'react';

//MM
import { connect } from 'react-redux';
import { getUserVisits, deleteVisit } from '../../store/visits';
import _ from 'lodash';
import 'react-day-picker/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


class AccountPageUserVisits extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.getUserVisits();
    };


    deleteVisit = (id) => {
        this.props.deleteVisit(id);
    };

    render() {

        return (
            <React.Fragment>
                <div className="row" >
                    <div className="col-md-12 d-flex">
                        <div className="flex-grow-1 mb-md-0">
                            <div className="card-body">
                                {this.props.userVisits.length != 0 && (
                                    <ul className="list-group" style={{fontSize:"24px"}}>
                                        {this.props.userVisits.map((visit) => <li key={visit.id} className="list-group-item align-items-center">{visit.date} | godzina: {visit.timeStarts} {visit.isActive ? <button type="button" onClick={(e) => this.deleteVisit(visit.id, e)} className="btn btn btn-danger m-1"><FontAwesomeIcon icon={faTrashAlt} /></button> : ''}</li>)}
                                    </ul>
                                )}
                                {this.props.userVisits.length == 0 && (
                                    <ul className="list-group">
                                        <li className="list-group-item align-items-center">Brak Wizyt</li>
                                    </ul>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        userVisits: _.values(state.visits.usersVisits)
    };
};


export default connect(mapStateToProps, { getUserVisits, deleteVisit })(AccountPageUserVisits);
