import axios from 'axios';

//export default axios.create({
//    baseURL: 'http://localhost:3000/api/Visits'
//});

export default axios.create({
    baseURL: 'https://www.witkowskiginekolog.pl/api/Visits'
});

//export default axios.create({
//    baseURL: 'https://webtodo.pl/api/Visits'
//});