// react
import React from 'react';

import { Link } from 'react-router-dom';


//MM
import { connect } from 'react-redux';
import { confirmResetPass } from '../../store/auth';

import { FormattedMessage } from 'react-intl';

class AccountPageResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Password: '',
            PasswordErr: false,
            ConfirmPassword: '',
            ConfirmPasswordErr: false,
            //pageTo: props.location.state || { from: { pathname: '/' } }
        };

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        switch (event.target.id) {
            case 'Password':
                this.setState({
                    PasswordErr: false
                });
                break;
         }
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#fafafa"
        //var token = this.props.match;
        //let { id } = this.props.match.params;
        //console.log(id);
    };

    onPasswordSubmit = (event) => {
        event.preventDefault();
        let newPass = this.state.Password;
        var token = this.props.match;
        let { id } = this.props.match.params;
        this.props.confirmResetPass(newPass, id);
    }

    render() {

        return (
            <React.Fragment>

                <div className="row" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px'
                }}>
                    <div className="col-md-3 d-flex mt-4 mt-md-0">
                        <div className="card flex-grow-1 mb-0">
                            <div className="card-body">
                                <h5 className="card-title text-center"><FormattedMessage id="account.login.newPass" defaultMessage="" /></h5>
                                <form onSubmit={this.onPasswordSubmit} className="ui form error">
                                    <div className="form-group">
                                        <label htmlFor="Email"><FormattedMessage id="account.login.password" defaultMessage="" /></label>
                                        <input
                                            id="Password"
                                            type="password"
                                            className="form-control"
                                            //style={this.state.EmailErr ? errInputStyle : defaultInputStyle}
                                            placeholder="Password"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Email"><FormattedMessage id="account.login.passwordConfirmation" defaultMessage="" /></label>
                                        <input
                                            id="ConfirmPassword"
                                            type="password"
                                            className="form-control"
                                            //style={this.state.EmailErr ? errInputStyle : defaultInputStyle}
                                            placeholder="Password"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <p><FormattedMessage id="account.register.passMin8" defaultMessage="" /></p>
                                    <button className="btn btn-primary btn-lg btn-block">
                                        <FormattedMessage id="common.btn.save" defaultMessage="" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                    <Link className="login" to="/">Strona główna</Link>
                </div>

            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        err: state.error
    };
};


export default connect(mapStateToProps, { confirmResetPass })(AccountPageResetPassword);

