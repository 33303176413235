export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const CHANGE_AUTH = 'CHANGE_AUTH';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const LOG_OUT = 'LOG_OUT';
export const AUTHENTICATE_THE_USER = 'AUTHENTICATE_THE_USER ';
export const SET_USER_ID = 'SET_USER_ID';
export const USER_IS_CONFIRMED = 'USER_IS_CONFIRMED';

//***Errors
//export const HTTP_401_ERROR = 'HTTP_401_ERROR';
//export const HTTP_403_ERROR = 'HTTP_401_ERROR';
//export const HTTP_404_ERROR = 'HTTP_404_ERROR';
//export const HTTP_500_ERROR = 'HTTP_500_ERROR';
//export const HTTP_OTHER_ERROR = 'HTTP_OTHER_ERROR';
//export const HTTP_CLEAR_ERROR = 'HTTP_CLEAR_ERROR';
